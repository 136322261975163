export default {
  0: {
    full: 'january',
    short: 'jan',
    genitive: 'january',
  },
  1: {
    full: 'february',
    short: 'feb',
    genitive: 'february'
  },
  2: {
    full: 'march',
    short: 'mar',
    genitive: 'march'
  },
  3: {
    full: 'april',
    short: 'apr',
    genitive: 'april'
  },
  4: {
    full: 'may',
    short: 'may',
    genitive: 'may'
  },
  5: {
    full: 'june',
    short: 'jun',
    genitive: 'june'
  },
  6: {
    full: 'july',
    short: 'jul',
    genitive: 'july'
  },
  7: {
    full: 'august',
    short: 'aug',
    genitive: 'august'
  },
  8: {
    full: 'september',
    short: 'sep',
    genitive: 'september'
  },
  9: {
    full: 'october',
    short: 'oct',
    genitive: 'october'
  },
  10: {
    full: 'november',
    short: 'nov',
    genitive: 'november'
  },
  11: {
    full: 'december',
    short: 'dec',
    genitive: 'december'
  }
};
