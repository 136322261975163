<template>
  <div class="member-view pb-5">
    <div class="view-header mb-4">
      <p class="heading-medium-big text-secondary mb-3">Welcome back, {{ this.$store.getters['getUserFirstName'] }}</p>
      <h2 class="heading-medium-big">Membership Plan</h2>
      <p class="sub-heading">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>

    <div v-if="allDataLoaded">
      <div class="flex">
        <div class="card width2-3 card-light">
          <div class="card-body">
            <template v-if="!data.membership_package">
              <div class="">
                <p class="heading-medium text-danger">
                  You did not purchase any membership plan.
                </p>
                <f-button
                  class="mt-3 mb-1 relative"
                  style="left: -11px;"
                  theme="outline-secondary"
                  big
                  @click.prevent="$bus.$emit('membership-plan-select-show')">
                  Buy Membership
                </f-button>
              </div>
            </template>
            <template v-else>
              <div class="">
                <h3
                  :class="{
                    'heading-medium-big': true,
                    'text-primary': !data.is_promotor,
                    'text-secondary': data.is_promotor
                  }">
                  {{ membershipPlan.name }}
                </h3>

                <p class="text-title-small text-italic my-0">{{ formatDate(data.membership_package.created_at) }} - {{ formatDate(data.membership_package.valid_to) }}</p>

                <p
                  :class="{
                    'heading-medium mt-4': true,
                    'text-danger': data.membership_package.status === 'expired' || data.membership_package.status === 'canceled',
                    'text-warning': data.membership_package.status === 'pending',
                  }">
                  {{ data.membership_package.status[0].toUpperCase() + data.membership_package.status.substring(1) }} Membership
                </p>

                <div
                  v-if="!(data.member_type === 'UT_FAMILY_MEMBER' && !data.is_parent_family_member)"
                  class="relative"
                  style="left: -11px;">
                  <template v-if="data.can_upgrade_membership">
                    <f-button
                      class="mt-2 mr-2 mb-1"
                      :theme="data.is_promotor ? 'outline-secondary' : 'outline'"
                      big
                      @click.prevent="$bus.$emit('membership-plan-select-show', data.new_package_id, data.price)">
                      Upgrade to {{ data.new_package }}
                    </f-button>
                  </template>

                  <template v-if="data.membership_package.status === 'expired'">
                    <f-button
                      class="mt-2 mr-2 mb-1"
                      theme="outline"
                      big
                      @click.prevent="$bus.$emit('membership-plan-select-show', data.membership_package.membership_package_id)">
                      Renew Membership
                    </f-button>
                  </template>
                  <template v-if="data.membership_package.status === 'canceled' || data.membership_package.status === 'expired'">
                    <f-button
                      class="mt-2 mr-2 mb-1"
                      theme="outline-secondary"
                      big
                      @click.prevent="$bus.$emit('membership-plan-select-show')">
                      Buy New Membership
                    </f-button>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div
          v-if="data.active_membership && (!(data.member_type === 'UT_FAMILY_MEMBER' && !data.is_parent_family_member))"
          class="card width1-3 ml-5 card-secondary">
          <div class="card-body text-center">
            <div>
              <p class="heading-medium-big text-light">
                <template v-if="data.is_promotor">
                  You are a Promoter
                </template>
                <template v-else>
                  Become a Promoter?
                </template>
              </p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidid loretto.</p>
            </div>
            <f-button
              v-if="!data.is_promotor"
              class="mt-2"
              theme="lightest"
              big
              @click.prevent="becomePromotor">
              Upgrade Plan
            </f-button>
            <a
              class="mt-2 f-btn f-btn-outline-lightest f-btn-big"
              :href="$http.defaults.frontSiteDomain + externalLinks.findOutMore.url"
              target="_blank"
              rel="nofollow noopener noreferrer">
              <span class="flex full-height flex-align-items-center">{{ externalLinks.findOutMore.label }}</span>
            </a>
          </div>
        </div>
      </div>
      <p class="ml-4 text-bold text-grey">If you'd like to make any changes to your membership please, <router-link class="lnk lnk-grey text-bold" to="/contact-us">Contact Us</router-link></p>
    </div>
    <div
      v-if="!allDataLoaded && !loadError"
      class="loading-spinner-wrapper">
      <div class="loading-spinner pb-3 pt-3">
        <span class="loading-spinner-content">
          Loading data&hellip;
        </span>
      </div>
    </div>

    <f-alert
      v-if="loadError"
      visible
      class="my-5"
      theme="warning">
      <span>
        Loading data failed.
        <a
          href="javascript:window.location.reload();"
          class="lnk lnk-alert">
          Reload page
        </a>
        to try again.
      </span>
    </f-alert>
    <membership-plan-select-popup />
  </div>
</template>
<script>
import FormUtils from '@/utils/FormUtils.js';
import CalendarUtils from '@/utils/CalendarUtils.js';
import MembershipPlanSelectPopup from '@/components/popups/MembershipPlanSelectPopup.vue';
import getExternalLink from '@/data/external-links';

export default {
  name: 'member-membership-plan',
  components: {
    'membership-plan-select-popup': MembershipPlanSelectPopup
  },
  computed: {
    allDataLoaded () {
      return this.loaded && this.packagesLoaded;
    },
    membershipPlan () {
      let currentPlan = this.allPackages.find((item) => item.id === this.data.membership_package.membership_package_id);

      if (currentPlan) {
        return currentPlan;
      }

      return 'Membership plan not found';
    }
  },
  data() {
    return {
      data: {
        id: 0,
        active: false,
        active_membership: false,
        can_upgrade_membership: false,
        created_at: null,
        deleted_at: null,
        email: null,
        email_verified_at: null,
        family_links: [],
        first_name: null,
        invite_token: null,
        is_admin: false,
        is_parent_family_member: false,
        is_promotor: false,
        last_name: null,
        member_type: 'UT_MEMBER',
        membership_package: null,
        new_package: null,
        new_package_id: null,
        nickname: null,
        parent_id: null,
        price: null,
        updated_at: null
      },
      invitee_email: null,
      loaded: false,
      loadError: false,
      submitInProgress: false,
      allPackages: [],
      packagesLoaded: false,
      memberTypes: [],
      memberTypesLoaded: false,
      actionInProgress: false,
      externalLinks: getExternalLink(),
    };
  },
  mounted() {
    this.$bus.$on('member-account-reload', this.loadItemData);
    this.loadItemData();
    this.loadAdditionalData();
  },
  methods: {
    loadItemData () {
      this.loaded = false;

      FormUtils.loadItemData(this, {
        id: null,
        method: 'get',
        endpointBase: '/api/member/profile',
        instanceFields: [
          'id',
          'active',
          'active_membership',
          'can_upgrade_membership',
          'created_at',
          'deleted_at',
          'email',
          'email_verified_at',
          'family_links',
          'first_name',
          'invite_token',
          'is_admin',
          'is_parent_family_member',
          'is_promotor',
          'last_name',
          'member_type',
          'membership_package',
          'new_package',
          'new_package_id',
          'nickname',
          'parent_id',
          'price',
          'updated_at'
        ],
        booleanFields: [
          'active',
          'active_membership',
          'is_admin',
          'is_promotor'
        ],
        loadedKey: 'loaded'
      });
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/packages/list',
        method: 'get',
        outputKey: 'allPackages',
        loadedKey: 'packagesLoaded',
        errorKey: 'loadError',
        noPagination: true
      });
    },
    formatDate (date) {
      if (!date) {
        return 'No information';
      }

      let dateObject = new Date(date);
      let monthName = CalendarUtils.getMonthName(dateObject.getMonth() + 1, true);
      monthName = monthName[0].toUpperCase() + monthName.substring(1);

      return monthName + ' ' + dateObject.getFullYear();
    },
    becomePromotor () {
      FormUtils.submitWithConfirm(this, {
        endpoint: '/api/member/upgrade-promoter',
        successTitle: 'Become a Promoter',
        successText: 'You will be now redirected to payment page.',
        confirmTitle: 'Please confirm that you want to become a Promoter?',
        confirmText: '',
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#0BBB66',
        errorTitle: 'An error has occurred',
        errorText: 'Operation failed. Please try again.',
        submitShowInfo: true,
        successAction: (response) => {
          window.open(response.payment_checkout, '_self');
        }
      });
    }
  },
  beforeDestroy () {
    this.$bus.$off('member-account-reload', this.loadItemData);
  }
}
</script>
