<template>
  <f-modal
    ref="video-tag-popup-modal"
    :class="{ 'is-visible': isVisible }"
    @close="handleClose">
    <f-modal-header>
      <h3 class="heading-medium">
        <template v-if="isRenew && !isUpgrade">
          Renew Membership
        </template>
        <template v-if="isUpgrade">
          Upgrade Membership
        </template>
        <template v-else>
          Select Membership Plan
        </template>
      </h3>
    </f-modal-header>

    <f-modal-body>
      <validation-observer
        v-if="allDataLoaded"
        ref="membership-plan-form"
        tag="form"
        @keydown.enter.prevent>

        <validation-provider
          rules="membership"
          name="membershipPackage"
          v-slot="{ errors }">

          <template v-if="isRenew || isUpgrade">
            <div
              :class="{
                'membership-package mt-2 mb-4 mx-auto': true,
                'membership-package-family': membershipPlan.member_type === 'FAMILY',
                'is-selected': membershipPlan.id === data.membership_package_id
              }"
              @click.prevent="selectPackage(membershipPlan.id)">
              <div class="heading-medium">{{ membershipPlan.name }}</div>
              <div class="heading-tiny mt-2">
                <template v-if="isUpgrade">
                 £{{ data.price }}
                </template>
                <template v-else>
                  £{{ membershipPlan.value }} {{ getSubscribtionPeriod(membershipPlan.valid_time) }}
                </template>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="membership-package-wrapper flex flex-wrap flex-justify-content-between">
              <div
                v-for="(item, index) in allPackages"
                :key="index"
                :class="{
                  'membership-package mt-2': true,
                  'membership-package-family': item.member_type === 'FAMILY',
                  'is-selected': item.id === data.membership_package_id
                }"
                @click.prevent="selectPackage(item.id)">
                <div class="heading-medium">{{ item.name }}</div>
                <div class="heading-tiny mt-2">£{{ item.value }} {{ getSubscribtionPeriod(item.valid_time) }}</div>
              </div>
            </div>
          </template>

          <input
            v-model="data.membership_package_id"
            type="hidden"
            :state="errors.length ? 'invalid' : null" />
          <div class="invalid-feedback text-center">
            {{ errors[0] }}
          </div>

        </validation-provider>
      </validation-observer>

      <div
        v-if="!allDataLoaded && !loadError"
        class="loading-spinner-wrapper">
        <div class="loading-spinner pb-3 pt-3">
          <span class="loading-spinner-content">
            Loading data&hellip;
          </span>
        </div>
      </div>

      <f-alert
        v-if="loadError"
        visible
        class="my-5"
        theme="warning">
        <span>
          Loading data failed.
          <a
            href="javascript:window.location.reload();"
            class="lnk lnk-alert">
            Reload page
          </a>
          to try again.
        </span>
      </f-alert>

    </f-modal-body>
    <f-modal-footer>
      <div class="flex flex-justify-content-between">
        <f-button
          class="ml-0 btn btn-danger"
          theme="danger"
          @click.prevent="handleClose">
          Cancel
        </f-button>
        <f-button
          class="ml-auto btn btn-primary"
          theme="primary"
          :disabled="submitInProgress"
          @click.prevent="validateForm">
          <div
            v-if="submitInProgress"
            class="loading-spinner"
            role="status">
          </div>
          Select Plan
        </f-button>
      </div>
    </f-modal-footer>
  </f-modal>
</template>
<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'membership-plan-select-popup',
  computed: {
    allDataLoaded () {
      return this.loaded && this.allPackages;
    },
    membershipPlan () {
      let currentPlan = this.allPackages.find((item) => item.id === this.data.membership_package_id);

      if (currentPlan) {
        return currentPlan;
      }

      return 'Membership plan not found';
    }
  },
  data() {
    return {
      data: {
        membership_package_id: 0,
        price: null
      },
      isVisible: false,
      submitInProgress: false,
      allPackages: [],
      packagesLoaded: false,
      isRenew: false,
      isUpgrade: false,
      loadError: false,
      loaded: false
    };
  },
  mounted() {
    this.$bus.$on('membership-plan-select-show', this.openPopup);
    this.loadAdditionalData();
  },
  methods: {
    handleClose() {
      document.body.classList.remove('no-scroll');
      this.isVisible = false;
    },
    openPopup(currentMembershipID = null, packagePrice = null) {
      this.loaded = false;
      this.loadError = false;
      this.isRenew = false;
      this.isUpgrade = false;
      this.submitInProgress = false;
      this.data.membership_package_id = 0;
      this.data.price = null;

      if (currentMembershipID) {
        this.data.membership_package_id = currentMembershipID;
        this.isRenew = true;

        if (packagePrice) {
          this.data.price = packagePrice;
          this.isUpgrade = true;
        }
      }

      Vue.nextTick(() => {
        this.loaded = true;
        this.isVisible = true;
        this.submitInProgress = false;
        document.body.classList.add('no-scroll');

        if (this.$refs['membership-plan-form']) {
          this.$refs['membership-plan-form'].reset();
        }
      });
    },
    validateForm () {
      FormUtils.validate(this.$refs['membership-plan-form'], this.submitForm);
    },
    submitForm () {
      if (!this.isRenew && !this.isUpgrade) {
        let formDataToSend = this.prepareFormDataToSend();

        FormUtils.submit(this, {
          endpoint: '/api/member/new-membership',
          formData: formDataToSend,
          successTitle: 'New membership purchase',
          successText: 'You will be now redirected to payment page.',
          errorTitle: 'An error has occurred',
          errorText: 'Membership subscription failed. Please try again.',
          submitShowInfo: true,
          successAction: (response) => {
            window.open(response.payment_checkout, '_self');
            this.handleClose();
          }
        });
      } else {
        let endpointUrl = this.isUpgrade ? '/api/member/upgrade-membership' : '/api/member/re-new-membership';
        let successTitleTxt = this.isUpgrade ? 'Membership plan updgrade' : 'Membership renewal';
        let confirmTitleTxt = this.isUpgrade ? 'Please confirm that you want to upgrade Your membership.' : 'Please confirm that you want to renew Your membership.';

        FormUtils.submitWithConfirm(this, {
          endpoint: endpointUrl,
          successTitle: successTitleTxt,
          successText: 'You will be now redirected to payment page.',
          confirmTitle: confirmTitleTxt,
          confirmText: '',
          confirmButtonText: 'Confirm',
          confirmButtonColor: '#0BBB66',
          errorTitle: 'An error has occurred',
          errorText: 'Operation failed. Please try again.',
          submitShowInfo: true,
          successAction: (response) => {
            window.open(response.payment_checkout, '_self');
            this.handleClose();
          }
        });
      }
    },
    loadAdditionalData() {
      FormUtils.loadAdditionalData(this, {
        endpoint: '/api/packages/list',
        method: 'get',
        outputKey: 'allPackages',
        loadedKey: 'packagesLoaded',
        errorKey: 'loadError',
        noPagination: true
      });
    },
    prepareFormDataToSend () {
      let formDataToSend = {
        membership_package_id: this.data.membership_package_id
      }

      return formDataToSend;
    },
    getSubscribtionPeriod (monthCount) {
      if (monthCount === 1) {
        return 'monthly';
      }

      if (monthCount === 12) {
        return 'yearly';
      }

      if (monthCount === 3) {
        return 'quarterly';
      }

      return '';
    },
    selectPackage (selectedID) {
      Vue.set(this.data, 'membership_package_id', selectedID);
    }
  },
  beforeDestroy () {
    this.$bus.$on('membership-plan-select-show', this.openPopup);
  }
}
</script>

<style lang="scss" soped>
  .f-modal-body {
    min-height: 200px;
    position: relative;
  }
</style>
