import MonthsNames from '@/data/months-names.js';
import DaysNames from '@/data/days-names.js';

export default class CalendarUtils {
  /**
   * Returns first day visible in calendar "card"
   */
  static getStartDay (year, month) {
    let date = new Date(year, month - 1, 1, 12, 0, 0, 0);
    let dayNumber = date.getDay();

    if (dayNumber > 0) {
      date.setTime(date.getTime() - ((dayNumber - 1) * 24 * 60 * 60 * 1000));
    } else if (dayNumber === 0) {
      date.setTime(date.getTime() - (6 * 24 * 60 * 60 * 1000));
    }

    return this.formatDateForPHP(date);
  }

  /**
   * Returns last day visible in calendar "card"
   */
  static getEndDay (year, month) {
    let dateTemp = new Date(year, month, 0, 12, 0, 0, 0);
    let date = new Date(year, month - 1, dateTemp.getDate(), 12, 0, 0, 0);
    let dayNumber = date.getDay();

    if (dayNumber > 0) {
      date.setTime(date.getTime() + ((7 - dayNumber) * 24 * 60 * 60 * 1000));
    }

    return this.formatDateForPHP(date);
  }

  /**
   * Returns date since provided current date with given diff
   */
  static getDay (currentDate, diff) {
    let dateArray = currentDate.split('-').map(num => parseInt(num, 10));
    let newDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2], 0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + diff);

    return this.formatDateForPHP(newDate);
  }

  /**
   * Returns list of dates in a given period
   */
  static getDaysList (year, month) {
    let start = this.getStartDay(year, month);
    let end = this.getEndDay(year, month);
    let startArray = start.split('-').map(num => parseInt(num, 10));
    let endArray = end.split('-').map(num => parseInt(num, 10));
    let endDate = new Date(endArray[0], endArray[1] - 1, endArray[2], 0, 0, 0, 0);
    let today = new Date();
    let tempDate = new Date(startArray[0], startArray[1] - 1, startArray[2], 0, 0, 0);
    let days = [];

    while (tempDate.getTime() <= endDate.getTime()) {
      days.push({
        number: tempDate.getDate(),
        date: this.formatDateForPHP(tempDate),
        isCurrentMonth: tempDate.getMonth() + 1 === month,
        isToday: today.getFullYear() === tempDate.getFullYear() && today.getMonth() === tempDate.getMonth() && today.getDate() === tempDate.getDate()
      });
      tempDate.setDate(tempDate.getDate() + 1);
    }

    return days;
  }

  /**
   * Format JS Date object for use with PHP REST API
   */
  static formatDateForPHP (date) {
    return [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(num => num < 10 ? '0' + num : num).join('-');
  }

  /**
   * Returns day name according to given day number
   */
  static getDayName (dayNumber, short = false) {
    switch (dayNumber) {
      case 1: return short ? DaysNames.day1.short : DaysNames.day1.full;
      case 2: return short ? DaysNames.day2.short : DaysNames.day2.full;
      case 3: return short ? DaysNames.day3.short : DaysNames.day3.full;
      case 4: return short ? DaysNames.day4.short : DaysNames.day4.full;
      case 5: return short ? DaysNames.day5.short : DaysNames.day5.full;
      case 6: return short ? DaysNames.day6.short : DaysNames.day6.full;
      case 0: return short ? DaysNames.day7.short : DaysNames.day7.full;
      default: return short ? DaysNames.day1.short : DaysNames.day1.full;
    }
  }

  /**
   * Returns day name according to given date
   */
  static getDayNameFromDate (date, short = false) {
    date = date.split('-').map(n => parseInt(n, 10));
    let dateObject = new Date(date[0], date[1] - 1, date[2]);
    let dayNumber = dateObject.getDay();

    return this.getDayName(dayNumber, short);
  }

  /**
   * Returns month name according to given month number
   */
  static getMonthName (monthNumber, short = false) {
    switch (monthNumber) {
      case 1: return short ? MonthsNames[0].short : MonthsNames[0].full;
      case 2: return short ? MonthsNames[1].short : MonthsNames[1].full;
      case 3: return short ? MonthsNames[2].short : MonthsNames[2].full;
      case 4: return short ? MonthsNames[3].short : MonthsNames[3].full;
      case 5: return short ? MonthsNames[4].short : MonthsNames[4].full;
      case 6: return short ? MonthsNames[5].short : MonthsNames[5].full;
      case 7: return short ? MonthsNames[6].short : MonthsNames[6].full;
      case 8: return short ? MonthsNames[7].short : MonthsNames[7].full;
      case 9: return short ? MonthsNames[8].short : MonthsNames[8].full;
      case 10: return short ? MonthsNames[9].short : MonthsNames[9].full;
      case 11: return short ? MonthsNames[10].short : MonthsNames[10].full;
      case 12: return short ? MonthsNames[11].short : MonthsNames[11].full;
      default: return short ? MonthsNames[0].short : MonthsNames[0].full;
    }
  }

  /**
   * Returns month name according to given month number
   */
  // static getMonthNameGenitive (monthNumber) {
  //   monthNumber = parseInt(monthNumber, 10);

  //   switch (monthNumber) {
  //     case 1: return window.bookero.translations.$t('calendar.month1.genitive');
  //     case 2: return window.bookero.translations.$t('calendar.month2.genitive');
  //     case 3: return window.bookero.translations.$t('calendar.month3.genitive');
  //     case 4: return window.bookero.translations.$t('calendar.month4.genitive');
  //     case 5: return window.bookero.translations.$t('calendar.month5.genitive');
  //     case 6: return window.bookero.translations.$t('calendar.month6.genitive');
  //     case 7: return window.bookero.translations.$t('calendar.month7.genitive');
  //     case 8: return window.bookero.translations.$t('calendar.month8.genitive');
  //     case 9: return window.bookero.translations.$t('calendar.month9.genitive');
  //     case 10: return window.bookero.translations.$t('calendar.month10.genitive');
  //     case 11: return window.bookero.translations.$t('calendar.month11.genitive');
  //     case 12: return window.bookero.translations.$t('calendar.month12.genitive');
  //     default: return window.bookero.translations.$t('calendar.month1.genitive');
  //   }
  // }

  /**
   * Returns days list for given month and year
   */
  static getMonthDays (month, year, useShortNames = true) {
    let numberOfDays = new Date(year, month, 0).getDate();
    let days = Array.from({ length: numberOfDays }, (v, k) => k + 1);

    return days.map(oneDay => {
      let tempDate = new Date(year, month - 1, oneDay);

      return {
        day: oneDay,
        name: this.getDayName(tempDate.getDay(), useShortNames)
      };
    });
  }

  /**
   * Get day names for a given range
   */
  static getDayNames (periodStart, diff) {
    let startArray = periodStart.split('-').map(num => parseInt(num, 10));
    let startDate = new Date(startArray[0], startArray[1] - 1, startArray[2], 0, 0, 0, 0);
    let names = [];

    names.push({
      name: startDate.getDate() + ' ' +
            this.getMonthName(startDate.getMonth() + 1, true) + ' - ' +
            this.getDayName(startDate.getDay()) + ':',
      date: this.formatDateForPHP(startDate)
    });

    for (let i = 1; i < diff; i++) {
      startDate.setDate(startDate.getDate() + 1);
      names.push({
        name: startDate.getDate() + ' ' +
              this.getMonthName(startDate.getMonth() + 1, true) + ' - ' +
              this.getDayName(startDate.getDay()) + ':',
        date: this.formatDateForPHP(startDate)
      });
    }

    return names;
  }

  /**
   * Calculates next/prev date
   */
  static getDiffDate (startDate, diff) {
    let startArray = startDate.split('-').map(num => parseInt(num, 10));
    let newDate = new Date(startArray[0], startArray[1] - 1, startArray[2], 0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + diff);

    return this.formatDateForPHP(newDate);
  }

  /**
   * Transform date to more human format
   */
  static humanDateFormat (date, withHour = true, showDayName = false) {
    if (withHour) {
      let tempParts = date.split(' ');
      let dateParts = tempParts[0].split('-');
      let timePart = tempParts[1];

      if (dateParts[2][0] === '0' && dateParts[2].length) {
        dateParts[2] = dateParts[2][1];
      }

      return [dateParts[2], this.getMonthNameGenitive(dateParts[1]), dateParts[0], window.bookero.translations.$t('calendar.other.at_hour'), timePart].join(' ');
    }

    let dateParts = date.split(' ');
    dateParts = dateParts[0];
    dateParts = dateParts.split('-');

    if (dateParts[2][0] === '0' && dateParts[2].length) {
      dateParts[2] = dateParts[2][1];
    }

    if (showDayName) {
      let dateObject = new Date(parseInt(dateParts[0], 10), parseInt(dateParts[1] - 1, 10), parseInt(dateParts[2], 10));
      let dayNumber = dateObject.getDay();
      let dayName = '';

      switch (dayNumber) {
        case 0: dayName = DaysNames.day7.full; break;
        case 1: dayName = DaysNames.day1.full; break;
        case 2: dayName = DaysNames.day2.full; break;
        case 3: dayName = DaysNames.day3.full; break;
        case 4: dayName = DaysNames.day4.full; break;
        case 5: dayName = DaysNames.day5.full; break;
        case 6: dayName = DaysNames.day6.full; break;
        default: dayName = DaysNames.day7.full; break;
      }

      return [dayName + ',', dateParts[2], this.getMonthNameGenitive(dateParts[1]), dateParts[0]].join(' ');
    }

    return [dateParts[2], this.getMonthNameGenitive(dateParts[1]), dateParts[0]].join(' ');
  }

  /**
   * Converts string YYYY-MM-DD to Date object
   */
  static convertStringToDate (stringDate) {
    let date = stringDate.split('-').map(num => parseInt(num, 10));
    let convertedDate = new Date(date[0], date[1] - 1, date[2], 0, 0, 0, 0);

    return convertedDate;
  }

  /**
   * Converts string YYYY-MM-DD hh:mm:ss to Date object
   */
  static convertDateTimeStringToDate (stringDate) {
    let datetime = stringDate.split(' ');
    let date = datetime[0].split('-').map(num => parseInt(num, 10));
    let time = datetime[1].split(':').map(num => parseInt(num, 10));
    let convertedDate = new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[2], 0);

    return convertedDate;
  }

  /**
   * Converts string to timestamp
   */
  static convertDateToTimestamp (stringDate) {
    return +CalendarUtils.convertStringToDate(stringDate);
  }

  /**
   * Converts timestamp to string
   */
  static convertTimestampToString (timestamp) {
    let date = new Date();
    date.setTime(timestamp);

    return [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(n => n < 10 ? '0' + n : n).join('-');
  }
};
