export default {
  day1: {
    full: 'monday',
    short: 'mon',
    shortPart1: 'mon',
    shortPart2: 'day'
  },
  day2: {
    full: 'tuesday',
    short: 'tue',
    shortPart1: 'tue',
    shortPart2: 'sday'
  },
  day3: {
    full: 'wednesday',
    short: 'wed',
    shortPart1: 'wed',
    shortPart2: 'nesday'
  },
  day4: {
    full: 'thursday',
    short: 'thu',
    shortPart1: 'thu',
    shortPart2: 'rsday'
  },
  day5: {
    full: 'friday',
    short: 'fri',
    shortPart1: 'fri',
    shortPart2: 'day'
  },
  day6: {
    full: 'saturday',
    short: 'sat',
    shortPart1: 'sat',
    shortPart2: 'urday'
  },
  day7: {
    full: 'sunday',
    short: 'sun',
    shortPart1: 'sun',
    shortPart2: 'day'
  }
};
