var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f-modal',{ref:"video-tag-popup-modal",class:{ 'is-visible': _vm.isVisible },on:{"close":_vm.handleClose}},[_c('f-modal-header',[_c('h3',{staticClass:"heading-medium"},[(_vm.isRenew && !_vm.isUpgrade)?[_vm._v(" Renew Membership ")]:_vm._e(),(_vm.isUpgrade)?[_vm._v(" Upgrade Membership ")]:[_vm._v(" Select Membership Plan ")]],2)]),_c('f-modal-body',[(_vm.allDataLoaded)?_c('validation-observer',{ref:"membership-plan-form",attrs:{"tag":"form"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}}},[_c('validation-provider',{attrs:{"rules":"membership","name":"membershipPackage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isRenew || _vm.isUpgrade)?[_c('div',{class:{
              'membership-package mt-2 mb-4 mx-auto': true,
              'membership-package-family': _vm.membershipPlan.member_type === 'FAMILY',
              'is-selected': _vm.membershipPlan.id === _vm.data.membership_package_id
            },on:{"click":function($event){$event.preventDefault();return _vm.selectPackage(_vm.membershipPlan.id)}}},[_c('div',{staticClass:"heading-medium"},[_vm._v(_vm._s(_vm.membershipPlan.name))]),_c('div',{staticClass:"heading-tiny mt-2"},[(_vm.isUpgrade)?[_vm._v(" £"+_vm._s(_vm.data.price)+" ")]:[_vm._v(" £"+_vm._s(_vm.membershipPlan.value)+" "+_vm._s(_vm.getSubscribtionPeriod(_vm.membershipPlan.valid_time))+" ")]],2)])]:[_c('div',{staticClass:"membership-package-wrapper flex flex-wrap flex-justify-content-between"},_vm._l((_vm.allPackages),function(item,index){return _c('div',{key:index,class:{
                'membership-package mt-2': true,
                'membership-package-family': item.member_type === 'FAMILY',
                'is-selected': item.id === _vm.data.membership_package_id
              },on:{"click":function($event){$event.preventDefault();return _vm.selectPackage(item.id)}}},[_c('div',{staticClass:"heading-medium"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"heading-tiny mt-2"},[_vm._v("£"+_vm._s(item.value)+" "+_vm._s(_vm.getSubscribtionPeriod(item.valid_time)))])])}),0)],_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.membership_package_id),expression:"data.membership_package_id"}],attrs:{"type":"hidden","state":errors.length ? 'invalid' : null},domProps:{"value":(_vm.data.membership_package_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "membership_package_id", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback text-center"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1983767237)})],1):_vm._e(),(!_vm.allDataLoaded && !_vm.loadError)?_c('div',{staticClass:"loading-spinner-wrapper"},[_c('div',{staticClass:"loading-spinner pb-3 pt-3"},[_c('span',{staticClass:"loading-spinner-content"},[_vm._v(" Loading data… ")])])]):_vm._e(),(_vm.loadError)?_c('f-alert',{staticClass:"my-5",attrs:{"visible":"","theme":"warning"}},[_c('span',[_vm._v(" Loading data failed. "),_c('a',{staticClass:"lnk lnk-alert",attrs:{"href":"javascript:window.location.reload();"}},[_vm._v(" Reload page ")]),_vm._v(" to try again. ")])]):_vm._e()],1),_c('f-modal-footer',[_c('div',{staticClass:"flex flex-justify-content-between"},[_c('f-button',{staticClass:"ml-0 btn btn-danger",attrs:{"theme":"danger"},on:{"click":function($event){$event.preventDefault();return _vm.handleClose.apply(null, arguments)}}},[_vm._v(" Cancel ")]),_c('f-button',{staticClass:"ml-auto btn btn-primary",attrs:{"theme":"primary","disabled":_vm.submitInProgress},on:{"click":function($event){$event.preventDefault();return _vm.validateForm.apply(null, arguments)}}},[(_vm.submitInProgress)?_c('div',{staticClass:"loading-spinner",attrs:{"role":"status"}}):_vm._e(),_vm._v(" Select Plan ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }